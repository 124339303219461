/**
 * @generated SignedSource<<4617adc7f39e184eebb38054b88b380c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuySharedRefetchContainer_buyPage$data = {
  readonly appliedFilters: ReadonlyArray<{
    readonly name: string | null;
    readonly values: ReadonlyArray<{
      readonly code: string | null;
      readonly displayName: string | null;
      readonly urlLabel: string | null;
    } | null> | null;
  } | null> | null;
  readonly appliedPersonalizationList: ReadonlyArray<string | null> | null;
  readonly displayUriRef: string | null;
  readonly pageType: string | null;
  readonly sort: {
    readonly currentOption: {
      readonly urlLabel: string | null;
    } | null;
  } | null;
  readonly totalResults: number | null;
  readonly trackedAbTests: ReadonlyArray<string | null> | null;
  readonly " $fragmentType": "BuySharedRefetchContainer_buyPage";
};
export type BuySharedRefetchContainer_buyPage$key = {
  readonly " $data"?: BuySharedRefetchContainer_buyPage$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuySharedRefetchContainer_buyPage">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlLabel",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuySharedRefetchContainer_buyPage",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "trackedAbTests",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pageType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalResults",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchBrowseParsedParam",
      "kind": "LinkedField",
      "name": "appliedFilters",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchBrowseParsedParamValue",
          "kind": "LinkedField",
          "name": "values",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "code",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SortType",
      "kind": "LinkedField",
      "name": "sort",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchBrowseSortCurrentOption",
          "kind": "LinkedField",
          "name": "currentOption",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayUriRef",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appliedPersonalizationList",
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};
})();

(node as any).hash = "76b3ab46804bfe9213af903a36830ab7";

export default node;
